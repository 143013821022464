import { createHttpEndpoint } from '../../utils'

import type { ShoppingSession } from './ShoppingSession.types'

/**
 * Retrieve a Shopping Session information
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-web-cart-api/definition#operation/CheckoutShoppingSessionGet}
 */
export const getShoppingSession = createHttpEndpoint<ShoppingSession>({
  method: 'GET',
  operationId: 'fetchInsurancePolicy',
  path: '/bm/checkout/shopping-session/:sessionId',
})

/**
 * Retrieve a Shopping Session information
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-web-cart-api/definition#operation/CheckoutShoppingSessionGet}
 */
export const postShoppingSession = createHttpEndpoint<{
  shoppingSessionId: string
}>({
  method: 'POST',
  operationId: 'createInsuranceSession',
  path: '/bm/checkout/shopping-session',
})

/**
 * Select an insurance offer for a specific Checkout Item
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-web-cart-api/definition#operation/CheckoutShoppingSessionSelectInsuranceOffer}
 */
export const postShoppingSessionSelectInsurance = createHttpEndpoint({
  method: 'POST',
  operationId: 'select_insurance_offer_shopping_session',
  path: '/bm/checkout/shopping-session/:shoppingSessionId/select-insurance',
})

/**
 * Accept the Insurance Agreement for a specific Checkout Item
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-web-cart-api/definition#operation/CheckoutShoppingSessionAcceptInsuranceAgreement}
 */
export const postShoppingSessionAcceptAgreement = createHttpEndpoint({
  method: 'POST',
  operationId: 'accept_agreement_shopping_session',
  path: '/bm/checkout/shopping-session/:shoppingSessionId/accept-agreement',
})
